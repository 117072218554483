@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 800ms;
  -webkit-transition: opacity 800ms;
  -moz-transition: opacity 800ms;
  -ms-transition: opacity 800ms;
  -o-transition: opacity 800ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 0ms;
  -webkit-transition: opacity 0ms;
  -moz-transition: opacity 0ms;
  -ms-transition: opacity 0ms;
  -o-transition: opacity 0ms;
}
